import React from "react";
import classnames from "classnames";
import Button from "components/Base/Button";
import SectionContent from "components/SectionContent";
import CustomerSwiper from "components/CustomerSwiper";
import * as styles from "./index.module.less";

interface ClientClaimItemProps {
  username?: string;
  logo?: string;
  logoAlt?: string;
  logoHeight?: number;
  logoHeightMb?: number;
  desc?: string;
}
interface ClientClaimProps {
  title?: string;
  desc?: string;
  buttonText?: string;
  buttonHref?: string;
  buttonType?: string;
  buttonTarget?: string;
  logoWallUrlPc?: string;
  logoWallAltPc?: string;
  logoWallWidthPc?: number;
  logoWallUrlMb?: string;
  logoWallAltMb?: string;
  logoWallWidthMb?: number;
  dataSource?: ClientClaimItemProps[];
  className?: string;
  useSwiper?: boolean;
}
const ClientClaim: React.FC<ClientClaimProps> = (props) => {
  const {
    className,
    title,
    desc,
    buttonText,
    buttonHref,
    buttonType = "primary",
    buttonTarget = "_blank",
    logoWallUrlPc,
    logoWallAltPc,
    logoWallWidthPc,
    logoWallUrlMb,
    logoWallAltMb,
    logoWallWidthMb,
    dataSource = [],
    useSwiper = true,
  } = props;

  return (
    <SectionContent
      className={classnames(styles.ClientClaimWrap, className)}
      title={title}
    >
      <p className={classnames(styles.ClientClaimDesc, {[styles.Hidden]: !desc})}>{desc}</p>
      <div className={styles.ClientClaimLogoWrap}>
        <img
          className={styles.ClientClaimLogoPc}
          src={logoWallUrlPc}
          alt={logoWallAltPc}
          style={{ width: logoWallWidthPc }}
        />
        <img
          className={styles.ClientClaimLogoMb}
          src={logoWallUrlMb}
          alt={logoWallAltMb}
          style={{ maxWidth: logoWallWidthMb }}
        />
      </div>
      <CustomerSwiper
        className={classnames({[styles.Hidden]: dataSource.length === 0})}
        useSwiper={useSwiper}
        dataSource={dataSource}
      ></CustomerSwiper>
      <div className="sd-button-group">
        <Button btnType={buttonType} href={buttonHref} target={buttonTarget}>
          {buttonText}
        </Button>
      </div>
    </SectionContent>
  );
};

export default ClientClaim;
