import React from 'react'
import Seo from 'components/seo'
import Layout from 'layouts/zh'
import Button from 'components/Base/Button'
import SolutionsBanner from 'components/SolutionsBanner'
import SectionContent from 'components/SectionContent'
import PageBottomCard from 'components/PageBottomCard'
import SolutionsSwiper from 'components/SolutionsSwiper'
import Scheme from 'components/Scheme'
import ScanCodeConsultButton from 'components/ScanCodeConsultButton'
import ClientClaim from 'components/ClientClaim'
import MaterialCard from 'components/MaterialCard'
import * as styles from './index.module.less'

const Insurance: React.FC = (props) => {
  return (
    <Layout {...props}>
      <InsuranceState />
    </Layout>
  )
}

const InsuranceState: React.FC = (props) => {
  return (
    <>
      <Seo
        title="保险行业数字化解决方案 ｜神策数据 - 神策数据解决方案"
        description="神策数据拥有丰富的保险行业数字化运营实践经验，为保险行业 TP50 的多家企业构建了以客户为中心的数字化经营体系，实现了完整的用户旅程管理，以及精准自动化营销能力。"
        keywords="保险行业数字化解决方案,保险客户旅程管理,保险数字化转型,神策数据"
        saTitle="官网 - 产品（行业解决方案）-保险"
      />
      <main className={styles.Bank}>
        <SolutionsBanner
          className="banner-wrap"
          title="保险行业解决方案"
          subTitle="构建核心渠道数字化经营体系，助力险企数字化转型"
          desc="搭建覆盖全渠道的全生命周期客户经营体系，赋能险企增量与存量业务升级，推动业务运营与组织建设的数字化转型，实现降本增效。"
          imgUrl={
            <img
              src="https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/solution/insurance/icon-banner.png?v2"
              alt="保险数字化运营,保险行业数字化经营"
              className="banner-icon"
            />
          }
          buttonGroup={
            <Button href="/doInsurance" target="_blank">
              体验行业 Demo
            </Button>
          }
        />
        <SectionContent
          className="insurance-solutions"
          title="险企核心渠道数字化经营解决方案"
          backgroundColor="#F8FBFD"
        >
          <SolutionsSwiper
            className="insurance-solutions-swiper"
            dataSource={[
              {
                className: 's-01',
                icon: 'report',
                tabText: <>搭建自营平台经营体系</>,
                title: '助力险企实现线上化客户迁移，提高直销平台客户转化率',
                imgUrl: 'https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/solution/insurance/s-01.png',
                imgWidth: 711,
                descList: [
                  {
                    title: '打造活动运营体系',
                    list: ['通过在线活动进行自营平台的拉新引流，完整追踪不同渠道来源用户的转化、裂变效果'],
                  },
                  {
                    title: '构建业务提升专题',
                    list: ['优化平台的使用体验并提供千人多面的个性化服务，提升自营平台的客户转化率'],
                  },
                ],
                buttonList: [
                  {
                    text: '体验行业 Demo',
                    href: '/doInsurance',
                    target: '_blank',
                    type: 'primary',
                  },
                ],
              },
              {
                className: 's-02',
                icon: 'online-customer',
                tabText: <>网电销业务数字化运营</>,
                title: '网电销渠道线索全流程精细化培育与转化',
                imgUrl: 'https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/solution/insurance/s-02.png',
                imgWidth: 693,
                descList: [
                  {
                    title: '全链路追踪线索表现，衡量真实 ROI',
                    list: [
                      '打通投放在媒体平台的“展点消”数据与进入私域平台后的注册转化等数据，科学客观衡量线索的投入与产出',
                    ],
                  },
                  {
                    title: '线索的多触点培育与转化',
                    list: [
                      '运用电销、企业微信、社群等不同运营手段交叉培育线索，提升线索意向及转化率，高效达成首投、复投、升级等经营指标',
                    ],
                  },
                ],
                buttonList: [
                  {
                    text: '体验行业 Demo',
                    href: '/doInsuranceAgent',
                    target: '_blank',
                    type: 'primary',
                  },
                ],
              },
              {
                className: 's-03',
                icon: 'agent',
                tabText: <>代理人线上线下一体化经营</>,
                title: '代理人队伍数字化管理与赋能',
                imgUrl: 'https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/solution/insurance/s-03.png',
                imgWidth: 714,
                descList: [
                  {
                    title: '赋能代理人个人展业与成长',
                    list: [
                      '从活动分享、计划书制作、客户管理等关键环节提升代理人展业效果，并优化线上培训体验牵引代理人成长',
                    ],
                  },
                  {
                    title: '打造代理人团队管理体系',
                    list: [
                      '通过业绩看板与展业工具使用情况，建立代理人管理体系，树立绩优代理人标杆画像，并通过预测模型降低代理人流失',
                    ],
                  },
                ],
                buttonList: [
                  {
                    text: '体验行业 Demo',
                    href: '/doInsuranceAgent',
                    target: '_blank',
                    type: 'primary',
                  },
                ],
              },
            ]}
          />
        </SectionContent>
        <SectionContent className="framework" title="保险行业解决方案全景架构图">
          <div className="framework-content">
            <img
              src="https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/solution/insurance/framework.png?v1"
              className="framework"
              alt="保险行业数字化解决方案"
            />
          </div>
          <div className="sd-button-group">
            <ScanCodeConsultButton
              buttonText="扫码咨询"
              qrcode={require(`assets/images/solution/game/panorama_code.png`).default}
            />
            <a className={styles.linkBtn} target="_blank" href="https://www.sensorsdata.cn/democenter/insurance.html">
              <span className={styles.textBtn}>场景解读</span> 
            </a>
          </div>
        </SectionContent>
        <SectionContent className="scheme-wrap" title="四大优势助力险企数字化转型" backgroundColor="#F8FBFD">
          <Scheme
            columnNum={4}
            useSwiper={false}
            itemHeight={200}
            dataSource={[
              {
                title: '深厚行业积淀',
                desc: '30+ 保险客户私有化部署服务案例，覆盖寿险、财险、健康险、保险中介机构与互联网保险，多家头部客户交付经验。',
                icon: 'https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/solution/insurance/t-01.png',
                alt: '深厚行业积淀',
              },
              {
                title: '全局业务支持',
                desc: '深耕保险业务，为险企 C 端平台、展业端平台、内勤系统等多个系统提供数字化运营服务，支持不同业态及场景。',
                icon: 'https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/solution/insurance/t-02.png',
                alt: '全局业务支持',
              },
              {
                title: '坚实数据根基',
                desc: '强大的数据采集技术与跨端数据整合能力，统一用户 ID，为险企打造定制化用户数据中台与数据根基平台。',
                icon: 'https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/solution/insurance/t-03.png',
                alt: '坚实数据根基',
              },
              {
                title: '专业服务团队',
                desc: '神策保险事业部拥有完善的行业服务体系，业务专家平均拥有 7+ 年行业经验，确保全周期服务质量。',
                icon: 'https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/solution/insurance/t-04.png',
                alt: '专业服务团队',
              },
            ]}
          />
          <div className="sd-button-group">
            <Button btnType="primary" href="/doInsurance" target="_blank">
              体验行业 Demo
            </Button>
          </div>
        </SectionContent>
        <ClientClaim
          title="我们服务的保险行业客户"
          desc="神策数据拥有丰富的保险行业数字化运营实践经验，已帮助险企 Top 50 中的 22 家构建了以客户为中心的数字化经营体系。"
          buttonText="体验行业 Demo"
          buttonHref="/doInsurance"
          buttonType="primary"
          buttonTarget="_blank"
          logoWallUrlPc="https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/solution/insurance/logo-wall.png"
          logoWallAltPc="logo wall"
          logoWallWidthPc={1006}
          logoWallUrlMb="https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/solution/insurance/logo-wall-mb.png"
          logoWallAltMb="logo wall"
          logoWallWidthMb={330}
          useSwiper={true}
          dataSource={[
            {
              username: '数字集团总裁  李军',
              logo: 'https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/logos/fhjk.png',
              logoAlt: '泛华金控',
              logoHeight: 34,
              logoHeightMb: 30,
              desc: '神策数据对数字化实践的前沿洞察和优质产品，为我们发展国内金融服务中介行业优质的销售及服务网络提供了坚实基础，其在“数据分析价值+专业服务”方面做到了知行合一，相信未来双方将碰撞出更多火花，携手加速保险业务在数据洞察与应用的创新发展，高效赋能代理人销售的同时为客户提供更佳服务体验！',
            },
            {
              username: '首席信息官&首席运营官 肖萍',
              logo: 'https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/logos/ergo.png',
              logoAlt: 'ERGO',
              logoHeight: 40,
              logoHeightMb: 35,
              desc: '希望与神策数据不断深化合作，聚焦价值转型，持续在用户精细化运营、私域流量运营等方面优化用户体验，进一步释放数字化价值，提升代理人等渠道的效率与效能，深化个险经营模式的创新，树立行业典范。',
            },
            {
              username: '科技与创新中心副总经理 郭峰',
              logo: 'https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/logos/chic.png',
              logoAlt: 'CHIC',
              logoHeight: 40,
              logoHeightMb: 34,
              desc: '与神策数据合作，应用其数据分析、用户画像、智能运营方案，我们首先在个人代理销售渠道建设中试点突破，验证了业务＋行为数据可视化分析以及实时迭代的智能运营，可高效助力业务目标达成，也能让传统保险从业人员运用数字化工具进行队伍管理和营销辅助。未来，我们将进一步深化华农保险数字化转型，继续与神策共建，将数字化能力继续扩展落地到公司经营管理、风控数据完善和 To C 直营建设，共同打造保险行业数字化运营新模式的样板间。',
            },
          ]}
        />
        <SectionContent title="行业洞察与观点" backgroundColor="#F8FBFD">
          <div className="more-brilliant-wrap">
            {[
              {
                title: '系统分析险企数字化业务能力打造的行业实践',
                href: 'https://www.sensorsdata.cn/market/playback-detail.html?id=3714',
                imgSrc: 'https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/solution/insurance/m-01.png',
              },
              {
                title: '深度洞察保险业数字化发展趋势与路径',
                href: 'https://www.sensorsdata.cn/school/library/b2fda8edbff031a4935cf15b930a495a',
                imgSrc: 'https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/solution/insurance/m-02.png',
              },
              {
                title: '专家解读提升客户线上经营效率的关键行动',
                href: 'https://www.sensorsdata.cn/market/playback-detail.html?id=3094',
                imgSrc: 'https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/solution/insurance/m-03.png',
              },
            ]?.map((item) => (
              <MaterialCard key={item.title} title={item.title} href={item.href} imgSrc={item.imgSrc} />
            ))}
          </div>
        </SectionContent>
        <PageBottomCard
          title="助力保险行业数字化转型破局突围！"
          desc="立即预约，神策数据保险行业专家与您共同打造竞争优势！"
          leftButtonText="体验行业 Demo"
          leftButtonHref="/doInsurance"
          rightButtonText="预约上门演示"
          rightButtonHref="https://www.sensorsdata.cn/form/parade.html"
        />
      </main>
    </>
  )
}

export default Insurance
